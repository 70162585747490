html {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;
}

body {
  height: 100%;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: visible;
  background-color: #f4f4f5;
}

body,
button {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 1.666;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Khula", sans-serif;
}

@font-face {
  font-family: "Khula";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("assets/fonts/khula.woff2") format("woff2");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("assets/fonts/lato.woff2") format("woff2");
}
